import React from "react"

import AuthFormWrapper from "../auth/components/AuthFormWrapper"
import ForgotPasswordForm from "../auth/forgot-password/components/ForgotPasswordForm"
import Seo from "../components/seo"

const ForgotPasswordPage: React.FC = () => {
  return (
    <AuthFormWrapper title="Forgot your password?">
      <Seo title="Yoco - Forgot password" />
      <ForgotPasswordForm />
    </AuthFormWrapper>
  )
}

export default ForgotPasswordPage
