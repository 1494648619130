import React, { useState } from "react"
import styled from "styled-components"
import { Formik } from "formik"
import * as Yup from "yup"
import { LabeledLink, Input, Button } from "../../../components"
import { useSendResetPasswordEmail } from "../../hooks"
import { validateEmail } from "../../../auth/validationObjects"

const DescriptionText = styled.p`
  font-size: 11px;
  line-height: 15px;
`

const validationSchema = Yup.object({
  email: validateEmail,
})

const ForgotPasswordForm: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false)
  const sendResetPasswordEmail = useSendResetPasswordEmail()

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          sendResetPasswordEmail(values)
          if (!emailSent) {
            setEmailSent(true)
          }
        } catch (err) {
          console.warn(err)
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Input placeholder="Email *" name="email" type="email" />
          <DescriptionText>
            Enter your account email so that we can send you further
            instructions for resetting your password.
          </DescriptionText>
          <Button
            label={emailSent ? "Resend email" : "Send email"}
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            color="#018567"
            textColor="white"
          />
          <LabeledLink
            label="Dont have an account?"
            linkText="Sign up"
            linkPath="/sign-up/"
          />
        </form>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
